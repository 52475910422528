import React, { useEffect } from "react";
import {
  signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login({ auth, isLoggedIn }) {
  const [showLoginError, setShowLoginError] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [auth, isLoggedIn, navigate]);

  // const signup = async (e) => {
  //   e.preventDefault();

  //   const userEmail = e.target.elements.userEmail.value;
  //   const userPassword = e.target.elements.userPassword.value;

  //   try {
  //     const userCredential = await createUserWithEmailAndPassword(
  //       auth,
  //       userEmail,
  //       userPassword
  //     );

  //   } catch (error) {
  //     setShowLoginError(true);
  //   }
  // };

  const login = async (e) => {
    e.preventDefault();

    const userEmail = e.target.elements.userEmail.value;
    const userPassword = e.target.elements.userPassword.value;

    try {
      await signInWithEmailAndPassword(auth, userEmail, userPassword);
    } catch (error) {
      setShowLoginError(true);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  return (
    <div>
      {isLoggedIn ? <p>You are logged in!</p> : <p>You are not logged in!</p>}
      <h1>Login </h1>
      <form onSubmit={login}>
        <div>
          <label htmlFor='email'>User Email:</label>
          <input type='text' id='email' name='userEmail' />
        </div>
        <div>
          <label htmlFor='password'>User Password:</label>
          <input type='password' id='password' name='userPassword' />
        </div>
        {showLoginError && <p>Incorrect Password</p>}
        <button type='submit'>Log in</button>
      </form>
      {/* <h1>Sign Up</h1>
      <form onSubmit={signup}>
        <div>
          <label htmlFor='email'>User Email:</label>
          <input type='text' id='email' name='userEmail' />
        </div>
        <div>
          <label htmlFor='password'>User Password:</label>
          <input type='password' id='password' name='userPassword' />
        </div>
        {showLoginError && <p>Incorrect Password</p>}
        <button type='submit'>Sign up</button>
      </form> */}
      {isLoggedIn && <button onClick={logout}>Sign Out</button>}
    </div>
  );
}

export default Login;
