import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const MenuItems = ({ items, depthLevel, handleHamburgerClick }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 768 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 768 && setDropdown(false);
  };

  const closeDropdown = (target) => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
    items.url !== "" && handleHamburgerClick();
  };

  return (
    <li
      className='menu-items'
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}>
      {items.url && items.submenu ? (
        <>
          <button
            type='button'
            aria-haspopup='menu'
            aria-expanded={dropdown ? "true" : "false"}
            // onClick={() => {
            //   setDropdown(true);
            // }}
          >
            {depthLevel === 0 ? (
              items.title
            ) : (
              <Link to={items.url}>{items.title}</Link>
            )}
            {depthLevel > 0 ? null : <span className='arrow' />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            handleHamburgerClick={handleHamburgerClick}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type='button'
            aria-haspopup='menu'
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}>
            {items.title}{" "}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className='arrow' />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            handleHamburgerClick={handleHamburgerClick}
          />
        </>
      ) : items.id === "google" ? (
        <a target='_blank' href={items.url} rel='noreferrer'>
          {items.title}
        </a>
      ) : (
        <HashLink key={items.title} to={items.url}>
          {items.title}
        </HashLink>
      )}
    </li>
  );
};

export default MenuItems;
