import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Layout from "./components/Layout";
import Fees from "./routes/Fees";
import Dashboard from "./routes/Dashboard";
import AppointmentRequestForm from "./routes/AppointmentRequestForm";
import { useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./routes/Login";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getDatabase } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementID: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Database
const database = getDatabase(app);
//Intialize auth
const auth = getAuth();

const App = () => {
  const [hamburgerMenu, setHamburgerMenu] = useState("HIDDEN");
  const handleHamburgerClick = () => {
    hamburgerMenu === "HIDDEN"
      ? setHamburgerMenu("SHOW")
      : setHamburgerMenu("HIDDEN");
  };

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  //runs once after the app is mounted. updates every time authstate changes
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(auth.currentUser);
      } else {
        setIsLoggedIn(null);
      }
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path='/'
          element={
            <Layout
              hamburgerMenu={hamburgerMenu}
              handleHamburgerClick={handleHamburgerClick}
            />
          }>
          <Route index element={<Home hamburgerMenu={hamburgerMenu} />} />
          <Route path='request-appt' element={<AppointmentRequestForm />} />
          <Route path='fees' element={<Fees />} />
          <Route
            path='login'
            element={<Login isLoggedIn={isLoggedIn} auth={auth} />}
          />
          <Route
            path='dashboard'
            element={
              <Dashboard
                database={database}
                isLoggedIn={isLoggedIn}
                auth={auth}
              />
            }
          />
          <Route path='*' element={<p>Not found!</p>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
