import { useField } from "formik";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { useEffect, useState } from "react";

const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid;
    color: #a0652d
  }
  .my-selected:hover:not([disabled]) { 
    border-color: #a0652d;
    color: #a0652d;
  }
  .my-today { 
    font-weight: bold;
    font-size: 140%; 
  }
`;

export const CustomDayPicker = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setSelected(field.value || "");
  }, [field.value]);

  const handleDayClick = (day) => {
    if (selected && day.toDateString() === selected.toDateString()) {
      helpers.setValue("");
      setSelected("");
    } else {
      helpers.setValue(day);
      setSelected(day);
    }
  };

  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = (
      <p style={{ fontWeight: "600" }}>
        Patient DOB: {format(selected, "PP")}.
      </p>
    );
  }

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <style>{css}</style>
      <DayPicker
        captionLayout='dropdown-buttons'
        fromYear={1923}
        toYear={2023}
        selected={field.value}
        onDayClick={handleDayClick}
        footer={footer}
        style={{ justifyContent: "center", display: "flex" }}
        modifiersClassNames={{
          selected: "my-selected",
          today: "my-today",
        }}
      />
      {meta.touched && meta.error ? (
        <div className='error-message'>{meta.error}</div>
      ) : null}
    </div>
  );
};
