export const menuItems = [
  {
    title: "About Us",
    url: "/#about-us",
  },
  {
    title: "Services",
    url: "",
    submenu: [
      {
        title: "Our Services",
        url: "/#services",
      },
      {
        title: "Process",
        url: "/#process",
      },
      {
        title: "Fees",
        url: "fees",
      },
    ],
  },
  {
    title: "Patient",
    url: "",
    submenu: [
      {
        title: "New Patient?",
        url: "/#process",
      },
      {
        title: "Therapy Appointment Portal",
        url: "https://portal.therapyappointment.com/index.cfm/public:auth?fw1pk=1",
        id: "google"
      },
    ],
  },
  {
    title: "Contact",
    url: "",
    submenu: [
      {
        title: "Request an Appointment",
        url: "/request-appt"
      },
      {
        title: "Contact",
        url: "/#contact"
      }
    ]
  }
];
