import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ hamburgerMenu, handleHamburgerClick }) => {
  return (
    <div className={"app " + (hamburgerMenu === "SHOW" && "no-touch")}>
      <Header
        hamburgerMenu={hamburgerMenu}
        handleHamburgerClick={handleHamburgerClick}
      />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
