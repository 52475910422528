import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Fees() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => {
    setDropdown(!dropdown);
  };

  return (
    <section className='fees-section'>
      <Helmet>
        <title>Fees - Cuppett Psychology Group</title>
        <meta
          name='description'
          content='Explore our transparent pricing structure at Cuppett Psychology Group. We believe in providing high-quality mental health services that are accessible and affordable. Understand our fee schedule and the insurance plans we accept.'
        />
        <link rel='canonical' href='https://www.cuppettpsychology.com/fees' />

        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content='https://www.cuppettpsychology.com/fees'
        />
        <meta property='og:title' content='Fees - Cuppett Psychology Group' />
        <meta
          property='og:description'
          content='Explore our transparent pricing structure at Cuppett Psychology Group. We believe in providing high-quality mental health services that are accessible and affordable. Understand our fee schedule and the insurance plans we accept.'
        />
        <meta property='og:image' content='%PUBLIC_URL%/social.png' />

        {/* Twitter */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content='https://www.cuppettpsychology.com/fees'
        />
        <meta
          property='twitter:title'
          content='Fees - Cuppett Psychology Group'
        />
        <meta
          property='twitter:description'
          content='Explore our transparent pricing structure at Cuppett Psychology Group. We believe in providing high-quality mental health services that are accessible and affordable. Understand our fee schedule and the insurance plans we accept.'
        />
        <meta property='twitter:image' content='%PUBLIC_URL%/social.png' />
      </Helmet>

      <h1>Fee Schedule</h1>
      <div className='no-surprise-container'>
        <h2>No Surprises Act Notice</h2>
        <div className='no-surprise-text'>
          <div className={dropdown ? "hidden" : ""}>
            <p>
              No Surprises Act "Good Faith Estimate" Notice 2022 You have the
              right to receive a "Good Faith Estimate" explaining how much your
              health care will cost.
            </p>
            <p>
              <span onClick={handleClick} className='read-more'>
                Read more...
              </span>
            </p>
          </div>
          <div className={!dropdown ? "hidden" : ""}>
            <p>
              No Surprises Act "Good Faith Estimate" Notice 2022 You have the
              right to receive a "Good Faith Estimate" explaining how much your
              health care will cost.
            </p>
            {/* <p>
              * Under the law, clients who do not have insurance or who are not
              choosing to use insurance have the right to receive a good faith
              estimate of their cost for health care services provided.
            </p> */}
            <p>
              * You have the right to receive a Good Faith Estimate for the
              total expected cost of any non-emergency items or services. This
              includes related costs like psychological testing and therapy
              fees.
            </p>
            <p>
              * You have the right to ask for and receive from a health care
              provider a Good Faith Estimate of your costs in writing at least 1
              business day before services are provided.
            </p>
            <p>
              * If you receive a bill that is at least $400 more than your Good
              Faith Estimate, you have the right to dispute the bill.
            </p>
            <p>
              * Make sure to save a copy or picture of your Good Faith Estimate.
            </p>
            <p>
              For questions or more information about your right to a Good Faith
              Estimate, visit{" "}
              <a
                href='http://www.cms.gov/nosurprises'
                target='_blank'
                rel='noreferrer'>
                cms.gov
              </a>
              .
            </p>
            <p>
              <span onClick={handleClick} className='read-more'>
                See less...
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className='fee-table-wrapper'>
        <table className='fee-table'>
          <tbody>
            <tr className='table-header'>
              <th>Service</th>
              <th>Hourly Rate</th>
              <th colSpan='2'>Total Cost</th>
            </tr>
            <tr className='row-odd'>
              <td>Counseling (in-person or virtual)</td>
              <td>
                $155.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                Estimated cost for 6 months of weekly counseling
                <span className='extra-info'>:</span>
              </td>
              <td>$3900</td>
            </tr>
            {/* <tr className='row-even'>
            <td>Counseling with masters provider (in-person or virtual)</td>
            <td>
            $120.00<span className='extra-info'>/hr</span>
            </td>
            <td>
            Estimated cost for 6 months of weekly counseling
            <span className='extra-info'>:</span>
            </td>
            <td>$3120</td>
          </tr> */}
            <tr className='row-odd'>
              <td>Consultation (in-person or virtual)</td>
              <td>
                $155.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                Variable cost based on one or multiple sessions
                <span className='extra-info'>:</span>
              </td>
              <td>$155</td>
            </tr>
            <tr className='row-even'>
              <td>ADHD evaluation</td>
              <td>
                $165.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                Estimated cost based on 7 total hours (interview, testing,
                scoring/report writing and feedback session)
                <span className='extra-info'>:</span>
              </td>
              <td>$1,155</td>
            </tr>
            <tr className='row-odd'>
              <td>
                Developmental Assessment (autism spectrum disorder, intellectual
                disability, general assessment of adaptive abilities)
              </td>
              <td>
                $165.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                Estimated cost based on 10 total hours (interview, testing,
                scoring/report writing and feedback session)
                <span className='extra-info'>:</span>
              </td>
              <td>$1650</td>
            </tr>
            <tr className='row-even'>
              <td>General Personality Assessment</td>
              <td>
                $165.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                Estimated cost based on 8 total hours (interview, testing,
                scoring/report writing and feedback session)
                <span className='extra-info'>:</span>
              </td>
              <td>$1320</td>
            </tr>
            <tr className='row-odd'>
              <td>
                Legal Work (court appearance, attorney consultation, court
                ordered evaluations)
              </td>
              <td>
                $225.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                16 hour retainer required to begin work (to include time related
                to telephone consultation, testing, report writing, court
                appearance, record review, multiple interviews with relevant
                parties)<span className='extra-info'>:</span>
              </td>
              <td>$3600</td>
            </tr>
            <tr className='row-even'>
              <td>Adoption Evaluations</td>
              <td>
                $120.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                Estimated cost for 3 hours including interview and a brief
                report. (If psychological testing is required additional 3 hours
                will be needed)
                <span className='extra-info'>:</span>
              </td>
              <td>$360</td>
            </tr>
            <tr className='row-odd'>
              <td>Learning Disabilities</td>
              <td>
                $165.00<span className='extra-info'>/hr</span>
              </td>
              <td>
                Estimated cost based on 10 total hours (interview, testing,
                scoring/report writing and feedback session)
                <span className='extra-info'>:</span>
              </td>
              <td>$1650</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Link to='/request-appt'>
        <button className='btn'>Request Appointment</button>
      </Link>
    </section>
  );
}
