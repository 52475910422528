import React from "react";

export default function Contact() {
  return (
    <section className='contact' id='contact'>
      <h1>Contact Us</h1>
      <div className='row'>
        <div className='contact-left'>
          <h2>Cuppett Psychology Group</h2>
          <h3>Physical Address</h3>
          <p>
            777 N Walnut Creek Dr.
            <br />
            Mansfield, TX 76063
            <br />
            Phone: 817-375-8890 (Call or text)
            <br />
            <a href='mailto:info@cuppettpsychology.com'>
              info@cuppettpsychology.com
            </a>
          </p>
          <p>
            <br />
            <br />
          </p>
          <h3>Correspondence Address</h3>
          <a href='mailto:info@cuppettpsychology.com'>
            info@cuppettpsychology.com
          </a>
          <br />
          Fax: 817-549-6795
        </div>
        <div className='contact-right'>
          <iframe
            className='google-map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3362.1232488515957!2d-97.13616668482305!3d32.576236981033105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e61098e4d53a3%3A0xfba0dee12d6f0ec!2sFirst%20Methodist%20Mansfield!5e0!3m2!1sen!2sus!4v1658793690567!5m2!1sen!2sus'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='google-map'></iframe>
        </div>
      </div>
    </section>
  );
}
