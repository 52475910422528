import React from "react";
import { useState } from "react";
import image1 from "../images/mountainLowRes.jpg";
import image2 from "../images/mountain2LowRes.jpg";

export default function Hero() {
  const heroImageList = [
    {
      alt: "Mountain",
      src: image1,
    },
    {
      alt: "Mountain",
      src: image2,
    },
  ];

  const [slideIdx, setSlideIdx] = useState(0);
  let timer = null;

  const nextSlide = () => {
    clearTimeout(timer);
    slideIdx < heroImageList.length - 1 && setSlideIdx(slideIdx + 1);
    slideIdx === heroImageList.length - 1 && setSlideIdx(0);
  };

  timer = setTimeout(nextSlide, 8000);

  if (window.location.pathname !== "/") {
    clearInterval(timer);
  }

  const currentSlide = heroImageList[slideIdx];

  return (
    <div id='hero'>
      <img
        src={currentSlide.src}
        alt={currentSlide.alt}
        className='hero-image'
      />
      <div className='quote-container'>
        <p className='hero-text' style={{ color: "white" }}>
          Psychological Services For The Family
        </p>
      </div>
    </div>
  );
}
