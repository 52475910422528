import React from "react";
import { Link } from "react-router-dom";

export default function Process({ hamburgerMenu }) {
  return (
    <section className='process' id='process'>
      <div className='process-title'>
        <h1>What is our process?</h1>
        <h3 className='hidden'>Insert tagline here...</h3>
      </div>
      <div className='row'>
        <div className='process-content'>
          <div className='progress-bar'>
            <div className='c1'></div>
            <div className='c2'></div>
            <div className='c3'></div>
          </div>
          <div className='row'>
            <div className='box1'>
              <h2>Step 1:</h2>
              <h3>Request Appointment</h3>
              <p>
                The first step is for you to fill out the appointment request
                form. We will respond with an email or phone call and determine
                how to best serve your needs.
              </p>
              <Link to='request-appt'>
                <button className='btn'>Request Appointment</button>
              </Link>
            </div>
            <div className='box2'>
              <h2>Step 2:</h2>
              <h3>Complete Forms</h3>
              <p>
                After deciding to move forward, you will receive an emailed
                invitation from our patient portal service called{" "}
                <a
                  href='https://portal.therapyappointment.com/index.cfm/public:auth?fw1pk=1'
                  target='_blank'
                  rel='noreferrer'>
                  Therapy Appointment
                </a>
                . This is where you will set up your client portal. The
                invitation expires after 48hrs.{" "}
                <span className='bold'>
                  All documents, forms, and credit card information must be
                  completed on{" "}
                  <a
                    href='https://portal.therapyappointment.com/index.cfm/public:auth?fw1pk=1'
                    target='_blank'
                    rel='noreferrer'>
                    Therapy Appointment
                  </a>{" "}
                  before your initial appointment.
                </span>{" "}
              </p>
            </div>
            <div className='box3'>
              <h2>Step 3:</h2>
              <h3>Initial Appointment</h3>
              <p>
                After completing the required forms on{" "}
                <a
                  href='https://portal.therapyappointment.com/index.cfm/public:auth?fw1pk=1'
                  target='_blank'
                  rel='noreferrer'>
                  Therapy Appointment
                </a>
                , we will contact you to set up a consultation appointment. This
                appointment is how we determine the best therapy or treatment
                plan for you. Once you become a patient you will use{" "}
                <a
                  href='https://portal.therapyappointment.com/index.cfm/public:auth?fw1pk=1'
                  target='_blank'
                  rel='noreferrer'>
                  Therapy Appointment
                </a>{" "}
                to book future appointments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
