import React, { useEffect, useState } from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { ScrollToFieldError } from "../components/ScrollToFieldError";
import { CustomDayPicker } from "../components/CustomDayPicker";
import { useNavigate } from "react-router-dom";
import Modal from "../UI/Modal";
import { Helmet } from "react-helmet";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  patientFirstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  patientLastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  patientEmail: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  phone: Yup.string()
    .matches(
      /^(\+1|1)?\s?-?\s?\(?\d{3}\)?\s?-?\s?\d{3}\s?-?\s?\d{4}$/,
      "Phone number is not valid"
    )
    .required("Phone number is required"),
  patientPhone: Yup.string()
    .matches(
      /^(\+1|1)?\s?-?\s?\(?\d{3}\)?\s?-?\s?\d{3}\s?-?\s?\d{4}$/,
      "Phone number is not valid"
    )
    .required("Phone number is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string()
    .matches(/^[0-9]{5}$/, "Invalid ZIP code")
    .required("ZIP code is required"),
  dob: Yup.date().required("Date of birth is required").nullable(),
  additionalInfo: Yup.string().optional(),
  // services: Yup.array().min(1, "Select at least one service"),
});

const services = [
  { label: "Counseling", value: "Counseling" },
  { label: "Evaluation & Testing", value: "Evaluation & Testing" },
  { label: "Consultation", value: "Consultation" },
  { label: "Not sure", value: "Not sure" },
];

const newPatient = [
  { label: "New Patient", value: "New Patient" },
  { label: "Returning Patient", value: "Returning Patient" },
];

const AppointmentRequestForm = () => {
  const CustomTextInput = ({ label, type, placeholder, ...props }) => {
    const [field, meta] = useField(props);
    if (type === "textarea") {
      return (
        <div className='form-row'>
          <label htmlFor={props.id || props.name}>{label}</label>
          <textarea
            className={`input-field ${
              meta.touched && meta.error ? "my-input-error" : ""
            }`}
            {...field}
            {...props}
            placeholder={placeholder}
          />
          {meta.touched && meta.error ? (
            <div className='error-message'>{meta.error}</div>
          ) : null}
        </div>
      );
    }
    return (
      <div className='form-row'>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input
          className={`input-field ${
            meta.touched && meta.error ? "my-input-error" : ""
          }`}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className='error-message'>{meta.error}</div>
        ) : null}
      </div>
    );
  };

  const CustomCheckbox = ({ children, ...props }) => {
    const [field] = useField({ ...props, type: "checkbox" });
    return (
      <div>
        <input type='checkbox' {...field} {...props} />
        <label className='checkbox-label'>{props.value}</label>
      </div>
    );
  };

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const onOpen = () => {
    setShowModal(true);
  };
  const onClose = () => {
    setShowModal(false);
    navigate("/");
  };

  useEffect(() => {
    // This function is run when the component is unmounted
    return () => {
      setShowModal(false);
    };
  }, []);

  if (showModal) {
    return (
      <Modal onClose={setShowModal}>
        <h2>Form received!</h2>
        <p>
          We will respond with an email or phone call and determine how to best
          serve your needs.
        </p>
        <div>
          <button type='button' onClick={onClose} className='btn'>
            Close
          </button>
        </div>
      </Modal>
    );
  }
  return (
    <section className='appointment-form-section'>
      <Helmet>
        <title>Request an Appointment - Cuppett Psychology Group</title>
        <meta
          name='description'
          content="Request an appointment with Cuppett Psychology Group. Fill out the simple form to schedule your session. We're committed to providing the highest quality mental health services tailored to your needs."
        />
        <link
          rel='canonical'
          href='https://www.cuppettpsychology.com/request-appt'
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content='https://www.cuppettpsychology.com/request-appt'
        />
        <meta
          property='og:title'
          content='Request an Appointment - Cuppett Psychology Group'
        />
        <meta
          property='og:description'
          content="Request an appointment with Cuppett Psychology Group. Fill out the simple form to schedule your session. We're committed to providing the highest quality mental health services tailored to your needs."
        />
        <meta property='og:image' content='%PUBLIC_URL%/social.png' />

        {/* <!-- Twitter --> */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content='https://www.cuppettpsychology.com/request-appt'
        />
        <meta
          property='twitter:title'
          content='Request an Appointment - Cuppett Psychology Group'
        />
        <meta
          property='twitter:description'
          content="Request an appointment with Cuppett Psychology Group. Fill out the simple form to schedule your session. We're committed to providing the highest quality mental health services tailored to your needs."
        />
        <meta property='twitter:image' content='%PUBLIC_URL%/social.png' />
      </Helmet>

      <div className='form-container'>
        <h1>Appointment Request Form</h1>

        <Formik
          initialValues={{
            firstName: "",
            patientFirstName: "",
            lastName: "",
            patientLastName: "",
            email: "",
            patientEmail: "",
            phone: "",
            patientPhone: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            dob: "",
            additionalInfo: "",
            services: [],
            newPatient: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            values.timestamp = new Date().toISOString();
            const formValues = JSON.stringify(values);
            fetch(`${process.env.REACT_APP_FIREBASE_DB_URL}/forms/appt.json`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: formValues,
            })
              .then(() => {
                actions.resetForm();
                onOpen();
              })
              .catch(() => {
                alert("There was an error. Please try again.");
              })
              .finally(() => actions.setSubmitting(false));
          }}>
          <Form
            className='appt-req-form'
            name='appointmentForm'
            method='post'
            data-netlify='true'
            data-netlify-honeypot='bot-field'>
            <input type='hidden' name='appt-req-form' value='appointmentForm' />
            <ScrollToFieldError />
            <div className='your-contact-info'>
              <h3>Your Contact Information</h3>
              <CustomTextInput
                label='First Name'
                name='firstName'
                type='text'
              />
              <CustomTextInput label='Last Name' name='lastName' type='text' />
              <CustomTextInput label='Email' name='email' type='email' />
              <CustomTextInput label='Phone' name='phone' type='text' />
            </div>

            <div className='your-contact-info'>
              <h3>Patient Information</h3>
              <div className='form-row-services'>
                {newPatient.map((option) => (
                  <div key={option.value} className='form-row-checkbox'>
                    <CustomCheckbox
                      key={option.value}
                      name='newPatient'
                      value={option.value}
                    />
                  </div>
                ))}
              </div>
              <CustomTextInput
                label='First Name'
                name='patientFirstName'
                type='text'
              />
              <CustomTextInput
                label='Last Name'
                name='patientLastName'
                type='text'
              />
              <CustomTextInput label='Email' name='patientEmail' type='email' />
              <CustomTextInput label='Phone' name='patientPhone' type='text' />
              <CustomTextInput label='Address' name='address' type='text' />
              <CustomTextInput label='City' name='city' type='text' />
              <CustomTextInput label='State' name='state' type='text' />
              <CustomTextInput label='Zip' name='zip' type='text' />
              <CustomDayPicker name='dob' label={"Date of birth"} />
              <h3>What type of services are you interested in?</h3>
              <div className='form-row-services'>
                {services.map((option) => (
                  <div key={option.value} className='form-row-checkbox'>
                    <CustomCheckbox
                      key={option.value}
                      name='services'
                      value={option.value}
                    />
                  </div>
                ))}
              </div>
              <CustomTextInput
                label='Additional Info'
                name='additionalInfo'
                type='textarea'
                placeholder='Is there anything else you want to request or share?'
              />
              <p className='disclaimer'>
                Please reserve confidential information for counseling sessions.
                Use this area for generic questions or requests.
              </p>
              <div className='line'></div>
              <p className='disclaimer'>
                After clicking submit, we will reply to your request within
                48hrs. Thank you for your interest, we look forward to serving
                you.
              </p>
            </div>

            <div className='form-row'>
              <button className='btn' type='submit'>
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </section>
  );
};

export default AppointmentRequestForm;
