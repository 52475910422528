import React from "react";
import paulHeadshot from "../images/paul-headshot.jpg";
import brittniHeadshot from "../images/Brittni-headshot2.jpg";

export default function AboutUs() {
  return (
    <section className='about2' id='about-us'>
      <h1>About Us</h1>
      <div className='row staff-card'>
        <div className='headshot-container'>
          <img className='headshots' src={paulHeadshot} alt='' />
        </div>
        <div className='bio-container'>
          <h2>Dr. Paul Cuppett, Psy, D </h2>
          <h3>Clinical Psychologist</h3>
          <p>
            With over two decades of experience in the field of psychology, Dr.
            Paul Cuppett is acutely aware that individuals often seek
            therapeutic and diagnostic services when life presents unexpected
            challenges. His academic journey, which started with a Master's
            degree in Counseling from Texas Christian University and culminated
            in a Doctorate in Clinical Psychology from the Georgia School of
            Professional Psychology, provided him with the foundational skills
            to serve the diverse mental health needs of clients.
            <br />
            <br />
            A significant facet of Dr. Cuppett's practice is psychological
            testing, a critical service he has honed since becoming a Licensed
            Psychologist in 2005. His aptitude in conducting thorough
            psychological evaluations allows him to diagnose a wide range of
            conditions, from learning difficulties and developmental disorders
            to more complex issues such as mood disorders and severe mental
            illness. This proficiency has also proved vital for individuals who
            require testing to fulfill certain requirements, such as those
            stipulated by the court or other entities.
            <br />
            <br />
            While Dr. Cuppett is skilled in providing therapeutic services for
            clients ranging from young children to adults, he emphasizes that
            psychological testing can stand alone as a service, separate from
            therapy. This flexibility allows individuals to receive the precise
            services they need, whether that be comprehensive evaluation,
            therapy, or both.
            <br />
            <br />
            In his interactions with clients, Dr. Cuppett is conscious of the
            diverse needs and belief systems they bring. He can offer a
            Christian perspective for those who prefer it, while always being
            respectful of those who don't. His primary objective remains
            consistent: meeting individuals where they are and supporting them
            without imposing his beliefs. This dedication to his clients is a
            testament to his professionalism and the success of his practice.
          </p>
        </div>
      </div>
      <div className='row staff-card'>
        <div className='headshot-container'>
          <img className='headshots' src={brittniHeadshot} alt='' />
        </div>
        <div className='bio-container'>
          <h2>Brittni Blevins, MA</h2>
          <h3>Licensed Psychological Associate</h3>
          <p>
            Brittni obtained her Master of Arts degree in Clinical Psychology at
            Roosevelt University in Chicago, IL and her Bachelor of Science
            degree in Psychology at Texas A&amp;M University in College Station,
            TX. Throughout her career, Brittni has worked in private, clinical
            and educational settings with clients ranging in age from young
            children to adults. Her goal is to help each client improve their
            mental health and reach their full potential by finding perspective
            and balance in all facets of life.
            <br />
            <br />
            Brittni's specialized training in the administration and
            interpretation of psychological and psychoeducational assessments
            allow her to identify areas of difficulty each client is
            experiencing such as attention deficit and learning disorders,
            developmental delays, anxiety and/or depression. She then works
            collaboratively with the client, family members, school personnel,
            and other treatment providers to develop the best plan of treatment
            for the client. Brittni is able to work with her clients both in
            person and virtually, allowing the client to participate from the
            space that is most convenient for them and where they feel most
            comfortable.
            <br />
            <br />
            Regardless of the setting, Brittni works to develop a strong
            therapeutic relationship with every client, providing a
            non-judgmental environment where everyone feels welcome, understood,
            and supported. By doing this, it is her belief each client can begin
            to explore, identify and build on their strengths, and ultimately
            feel empowered to become the best version of themselves. Outside of
            work, Brittni enjoys traveling, scuba diving, hiking, camping and
            spending time with her husband and 3 boys.
          </p>
        </div>
      </div>
      <div className='row staff-card hidden'>
        <div className='headshot-container'>
          <img
            className='headshots hidden'
            src='./images/head-shot-200x200.jpeg'
            alt=''
          />
        </div>
        <div className='bio-container'>
          <h2>Amy, BA</h2>
          <h3>Administrative Assisant</h3>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Atque
            facere incidunt corrupti debitis commodi laboriosam, iure quis,
            deserunt at temporibus quod est veniam totam beatae voluptatem a.
            Accusantium, ex maxime?
          </p>
        </div>
      </div>
    </section>
  );
}
