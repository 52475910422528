import { menuItems } from "../menuItems";
import MenuItems from "./MenuItems";
import hamburgerIcon from "../icons/bx-menu.svg";
import closeIcon from "../icons/bx-x.svg";

const Navbar = ({ hamburgerMenu, handleHamburgerClick }) => {
  return (
    <nav>
      <ul className={"menus " + (hamburgerMenu === "SHOW" && "showMenus")}>
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
              handleHamburgerClick={handleHamburgerClick}
            />
          );
        })}
      </ul>
      <div className={hamburgerMenu === "HIDDEN" ? "hamburger-container" : ""}>
        <img
          src={hamburgerMenu === "HIDDEN" ? hamburgerIcon : closeIcon}
          onClick={handleHamburgerClick}
          className={
            hamburgerMenu === "HIDDEN" ? "hamburger" : "hamburger white"
          }
          alt='menu icon'></img>
      </div>
    </nav>
  );
};

export default Navbar;
