import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Services() {
  const [listVisibility, setListVisibility] = useState("HIDDEN");

  const handleClick = () => {
    listVisibility === "HIDDEN"
      ? setListVisibility("SHOW")
      : setListVisibility("HIDDEN");
  };

  const visibility = listVisibility === "SHOW" ? "" : "hidden";

  return (
    <section className='about' id='services'>
      <h1>Our Services</h1>
      <div className='row'>
        <div className='about-left'>
          <h2>Counseling</h2>
          <p>
            We serve children, teens, adults, couples, and families desiring
            counseling for:
          </p>
          <ul>
            <li>Anxiety/Depression</li>
            <li>Behavior Disorders</li>
            <li>Marital Challenges</li>
            <li>Relational Struggles</li>
            <li>Addiction</li>
            <li>Parenting</li>
          </ul>
          <Link to='request-appt'>
            <button className='btn'>Request Appointment</button>
          </Link>
        </div>
        <div className='about-middle'>
          <h2>Testing & Evaluations</h2>
          <p>
            We offer psychological testing and evaluation for the following:
          </p>
          <ul>
            <li>ADHD</li>
            <li>Autism/Spectrum</li>
            <li>Learning Disorders</li>
            <li>Behavior Problems</li>
            <li>Adoption Evaluations</li>
            <li>Pre-operative Psychological Testing</li>
            <li className={visibility}>Intellectual Disabilities</li>
            <li className={visibility}>Personality Assessment</li>
            <li className={visibility}>
              Major Mental Illness (Bipolar Disorder, Schizophrenia, Major
              Depression, etc.)
            </li>
            <li className={visibility}>Court-Related (non-custody)</li>
            <li onClick={handleClick} className='toggle-view'>
              {listVisibility === "HIDDEN" ? "See full list..." : "See less..."}
            </li>
          </ul>
          <Link to='request-appt'>
            <button className='btn'>Request Appointment</button>
          </Link>
        </div>
        <div className='about-right'>
          <h2>Consultations</h2>
          <p>
            We provide a one-time consultation for those seeking direction and
            an action plan. It may be an individual or family crisis and/or you
            are looking for a little guidance.
          </p>
          <Link to='request-appt'>
            <button className='btn'>Request Appointment</button>
          </Link>
        </div>
      </div>
    </section>
  );
}
