import React from "react";

function formatDateToMMDDYYYY(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

const convertToMMDDYYYYWithTime = (isoString) => {
  const date = new Date(isoString);

  // JavaScript's getMonth starts from 0, hence adding 1
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  hours = hours % 12;
  // Convert '0' hours to '12' for 12-hour clock
  hours = hours ? hours : 12;

  // Convert month, day, hours, minutes and seconds to 2 digits format
  const twoDigitMonth = month.toString().padStart(2, "0");
  const twoDigitDay = day.toString().padStart(2, "0");
  const twoDigitHours = hours.toString().padStart(2, "0");
  const twoDigitMinutes = minutes.toString().padStart(2, "0");
  const twoDigitSeconds = seconds.toString().padStart(2, "0");

  return `${twoDigitMonth}/${twoDigitDay}/${year} ${twoDigitHours}:${twoDigitMinutes}:${twoDigitSeconds} ${amOrPm}`;
};

function SubmissionCard({
  additionalInfo,
  address,
  city,
  dob,
  email,
  firstName,
  lastName,
  newPatient,
  patientEmail,
  patientFirstName,
  patientLastName,
  patientPhone,
  phone,
  services,
  state,
  zip,
  timestamp,
}) {
  const convertedDOB = formatDateToMMDDYYYY(dob);
  const convertedTimestamp = convertToMMDDYYYYWithTime(timestamp);
  return (
    <div className='submission-card'>
      <div className='submission-section'>
        <h3>{convertedTimestamp}</h3>
        <h3>Submitter Info</h3>
        <p>
          {firstName} {lastName}
        </p>
        <p>{phone}</p>
        <p>{email}</p>
        <div className='address-wrap'>
          <p>{address}</p>
          <p>
            {city}, {state} {zip}
          </p>
        </div>
      </div>

      <div className='submission-section'>
        <div>
          <h3>Patient Info</h3>
          <span>
            {patientFirstName} {patientLastName}
          </span>
          {newPatient?.map((item) => (
            <span key={item} style={{ fontStyle: "italic" }}>
              {" "}
              - {item}
            </span>
          ))}
          <p>{patientPhone}</p>
          <p>{patientEmail}</p>
          <p>DOB: {convertedDOB}</p>
          <h3>Desired Services</h3>
          {!services && <p>No selection</p>}
          <ul style={{ paddingLeft: "4px", listStylePosition: "inside" }}>
            {services?.map((service) => (
              <li key={service}>{service}</li>
            ))}
          </ul>
          <h3>Additional Info</h3>
          <p>{additionalInfo ? additionalInfo : "blank"}</p>
        </div>
      </div>
    </div>
  );
}

export default SubmissionCard;
