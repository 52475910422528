import React from "react";
import AboutUs from "../components/AboutUs";
import Contact from "../components/Contact";
import Faq from "../components/Faq";
import Hero from "../components/Hero";
import Process from "../components/Process";
import Services from "../components/Services";

const Home = ({ hamburgerMenu }) => {
  return (
    <div id='main'>
      <Hero />
      <Services />
      <AboutUs />
      <Process hamburgerMenu={hamburgerMenu} />
      <Faq />
      <Contact />
    </div>
  );
};

export default Home;
