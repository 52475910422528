import React, { useEffect, useState } from "react";
import { ref, child, get } from "firebase/database";
import SubmissionCard from "../components/SubmissionCard";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";

function Dashboard({ database, isLoggedIn, auth }) {
  const [formSubmissions, setFormSubmissions] = useState(null);

  const convertToArray = (obj) => {
    let resultArray = [];

    for (let key in obj) {
      resultArray.push(obj[key]);
    }

    resultArray.sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp);
    });

    return resultArray;
  };

  useEffect(() => {
    const dbRef = ref(database);
    if (isLoggedIn) {
      console.log(dbRef);
      get(child(dbRef, `forms/appt`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val());
            setFormSubmissions(convertToArray(snapshot.val()));
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [database, isLoggedIn]);

  const fetchSubmissions = () => {
    const dbRef = ref(database);
    get(child(dbRef, `forms/appt`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setFormSubmissions(convertToArray(snapshot.val()));
        } else {
        }
      })
      .catch((error) => {
        console.error("We've encountered an error:", error);
      });
  };

  const logout = async () => {
    await signOut(auth);
  };

  if (!isLoggedIn) {
    return (
      <div>
        <p>You are not logged in.</p>
        <Link to='/login'>Login Here</Link>
      </div>
    );
  }

  if (!formSubmissions) {
    return <p>No data</p>;
  }

  return (
    <div>
      <h1>Form Sumbissions</h1>
      <button className='btn' onClick={fetchSubmissions}>
        Refresh Submissions
      </button>
      <button className='btn' onClick={logout}>
        Logout
      </button>
      <div>
        {formSubmissions.map((sub, idx) => {
          return (
            // TODO: update key with a real value
            <SubmissionCard
              key={idx}
              additionalInfo={sub.additionalInfo}
              address={sub.address}
              city={sub.city}
              dob={sub.dob}
              email={sub.email}
              firstName={sub.firstName}
              lastName={sub.lastName}
              newPatient={sub.newPatient}
              patientEmail={sub.patientEmail}
              patientFirstName={sub.patientFirstName}
              patientLastName={sub.patientLastName}
              patientPhone={sub.patientPhone}
              phone={sub.phone}
              services={sub.services}
              state={sub.state}
              zip={sub.zip}
              timestamp={sub.timestamp}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Dashboard;
