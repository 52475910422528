import React from "react";

export default function Faq() {
  return (
    <section className='faq' id='faq'>
      <div className='row'>
        <div className='faq-content'>
          <h1>Frequently Asked Questions</h1>
          <h3>What are your fees?</h3>
          <p>
            Here is our{" "}
            <a className='list-link' href='/fees' rel='noreferrer'>
              Fee Schedule
            </a>
            .
          </p>
          <h3>Do you accept insurance?</h3>
          <p>
            We do not accept insurance as payment, nor submit claims for
            services rendered. We are happy to provide a statement should you
            wish to file for reimbursement yourself. Please note: Insurance
            companies consider us an out-of-network provider and may reduce
            benefit payments.
          </p>
          <h3>What payments are accepted?</h3>
          <p>
            We accept all major credit cards, HSA, and Flex Spending cards. We
            keep your card information on file should you incur late
            cancellation or no-show fees. Payments are expected at the time of
            services.
          </p>
          <h3>What is your cancellation policy?</h3>
          <p>
            We require 24 hour notice for cancellations. A no show/late
            cancellation fee of $95.00 will be charged to the credit card on
            file. We certainly understand emergencies! Please call or text the
            office if you have an emergency and need to cancel. (817-375-8890)
          </p>
        </div>
      </div>
    </section>
  );
}
