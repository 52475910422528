import { Link } from "react-router-dom";
import Navbar from "./Navbar";

const Header = ({ hamburgerMenu, handleHamburgerClick }) => {
  return (
    <header id='home'>
      <div className='nav-area'>
        <Link to='/' className='logo'>
          <div className='logo-long'>Cuppett Psychology Group</div>
          <div className='logo-short'>
            Cuppett <br />
            Psychology
            <br /> Group
          </div>
        </Link>
        <Navbar
          hamburgerMenu={hamburgerMenu}
          handleHamburgerClick={handleHamburgerClick}
        />
      </div>
    </header>
  );
};

export default Header;
