import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <p>
        &copy; 2023 Cuppett Psychology
        <br />
        Designed and developed by{" "}
        <a href='https://mattbowen03.dev' target='_blank' rel='noreferrer'>
          Matt Bowen
        </a>
      </p>
      <Link to='/login'>Login</Link>
    </footer>
  );
}
